<template>
  <div class="p-3 text-end">
    <a
      href="javascript:void(0);"
      class="btn small-cta"
      data-bs-target="#discussionThreadModal"
      aria-haspopup="true"
      data-bs-toggle="modal"
      @click="clearData"
      ref="openModal"
      >New Thread</a
    >
  </div>
  <DiscussionSlot
    @editDiscussion="getDiscussion"
    ref="discussionSlot"
    :togglePostCount="true"
  />

  <div
    class="modal fade"
    id="discussionThreadModal"
    tabindex="-1"
    aria-labelledby="discussionThreadModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="discussionThreadModalLabel">
            {{ actionTitle }} Discussion Thread
          </h5>
          <button
            type="button"
            class="btn-close"
            ref="closeModal"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <form @submit.prevent="saveDiscussion" ref="formContainer">
          <div class="modal-body">
            <div class="col-md-12 mb-3">
              <label class="form-label">Course <span>*</span></label>
              <Multiselect
                v-model="programOptions.value"
                v-bind="programOptions"
                @select="fetchActiveSubscribedProgramTeachers"
              />
              <div
                class="input-errors"
                v-for="error of v$.programOptions.value.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label class="form-label">Teacher <span>*</span></label>
              <Multiselect
                v-model="teacherOptions.value"
                v-bind="teacherOptions"
                @select="fetchActiveSubscribedProgramSubjects"
              />
              <div
                class="input-errors"
                v-for="error of v$.teacherOptions.value.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label class="form-label">Subject <span>*</span></label>
              <Multiselect
                v-model="subjectOptions.value"
                v-bind="subjectOptions"
              />
              <div
                class="input-errors"
                v-for="error of v$.subjectOptions.value.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="mb-3">
                <label class="form-label">
                  Discussion Thread About
                  <span>*</span>
                </label>
                <textarea class="form-control" v-model="discussion"></textarea>
                <div
                  class="input-errors"
                  v-for="error of v$.discussion.$errors"
                  :key="error.$uid"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button> -->
            <button type="submit" class="btn cta-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DiscussionSlot from "@/components/DiscussionSlot.vue";
import CommonService from "@/services/CommonService";
import DiscussionService from "@/services/DiscussionService";
import Multiselect from "@vueform/multiselect";
import useVuelidate from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";

export default {
  name: "DiscussionTab",
  components: {
    DiscussionSlot,
    Multiselect,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      actionTitle: "New",
      discussion: "",
      fullPage: true,
      // Select options
      programOptions: {
        value: null,
        options: [],
        placeholder: "Select Course",
        noOptionsText: "No courses found",
        canDeselect: false,
        loading: false,
        // required: true,
        searchable: true,
      },
      teacherOptions: {
        value: null,
        options: [],
        placeholder: "Select Teacher",
        noOptionsText: "Select course first",
        canDeselect: false,
        loading: false,
        // required: true,
        searchable: true,
      },
      subjectOptions: {
        value: null,
        options: [],
        placeholder: "Select Subject",
        noOptionsText: "Select teacher first",
        canDeselect: false,
        loading: false,
        // required: true,
        searchable: true,
      },
      discussionId: "",
      isEditing: false,
      teacherId: "",
      subjectId: "",
    };
  },
  validations() {
    return {
      discussion: {
        required: helpers.withMessage(
          "Please enter discussion thread",
          required
        ),
        maxLength: maxLength(2400),
      },
      programOptions: {
        value: {
          required: helpers.withMessage("Please select course", required),
        },
      },
      subjectOptions: {
        value: {
          required: helpers.withMessage("Please select subject", required),
        },
      },
      teacherOptions: {
        value: {
          required: helpers.withMessage("Please select teacher", required),
        },
      },
    };
  },
  mounted() {
    this.fetchActiveSubscribedPrograms();
  },
  methods: {
    async fetchActiveSubscribedPrograms() {
      this.programOptions.loading = true;
      await CommonService.getActiveSubscribedPrograms()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.programOptions.value = null; // to clear previously selected value
            this.programOptions.options = []; // to clear previously loaded options
            let programs = response.data.programs;
            if (programs.length > 0) {
              programs.map((program) => {
                let options = {
                  value: program.id,
                  label: program.program_title,
                };
                this.programOptions.options.push(options);
              });
            } else {
              this.programOptions.noOptionsText = "No courses found";
            }
            this.programOptions.loading = false;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            let errorsObject = response.data.errors;
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchActiveSubscribedProgramTeachers() {
      this.teacherOptions.loading = true;
      this.teacherOptions.value = null; // to clear previously selected value
      this.subjectOptions.value = null; // to clear previously selected value
      this.teacherOptions.options = []; // to clear previously loaded options
      this.subjectOptions.options = []; // to clear previously loaded options
      let params = {
        program_id: this.programOptions.value,
      };
      await CommonService.getActiveSubscribedProgramTeachers(params)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            let isOptionAvailable = false;
            let teachers = response.data.teachers;
            if (teachers.length > 0) {
              teachers.map((teacher) => {
                let options = {
                  value: teacher.id,
                  label: teacher.teacher_name,
                };
                this.teacherOptions.options.push(options);
                if (teacher.id === this.teacherId) {
                  isOptionAvailable = true;
                }
              });
              if (this.isEditing == true && isOptionAvailable === true) {
                this.teacherOptions.value = this.teacherId;
                this.fetchActiveSubscribedProgramSubjects();
              }
            } else {
              this.teacherOptions.noOptionsText = "No teachers found";
            }
            this.teacherOptions.loading = false;
            // console.log(this.teacherOptions);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchActiveSubscribedProgramSubjects() {
      this.subjectOptions.loading = true;
      let params = {
        program_id: this.programOptions.value,
        teacher_id: this.teacherOptions.value,
      };
      await CommonService.getActiveSubscribedProgramSubjects(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            let isOptionAvailable = false;
            this.subjectOptions.value = null; // to clear previously selected value
            this.subjectOptions.options = []; // to clear previously loaded options
            let subjects = response.data.subjects;
            if (subjects.length > 0) {
              subjects.map((subject) => {
                let options = {
                  value: subject.id,
                  label: subject.subject_title,
                };
                this.subjectOptions.options.push(options);
                if (subject.id === this.subjectId) {
                  isOptionAvailable = true;
                }
              });
              if (this.isEditing == true && isOptionAvailable === true) {
                this.subjectOptions.value = this.subjectId;
              }
            } else {
              this.subjectOptions.noOptionsText = "No subjects found";
            }
            this.subjectOptions.loading = false;
            // console.log(this.subjectOptions);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveDiscussion() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        // console.log(this.v$.$errors);
        return;
      }
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
      });

      let params = {
        program_id: this.programOptions.value,
        subject_id: this.subjectOptions.value,
        teacher_id: this.teacherOptions.value,
        discussion: this.discussion,
        discussion_id: this.discussionId,
      };
      // console.log(params);
      DiscussionService.saveDiscussion(params)
        .then((response) => {
          loader.hide();
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            // trigger the function declared inside the DiscussionSlot component
            this.$refs.discussionSlot.switchRoute();
            this.$refs.closeModal.click();
            this.v$.$reset(); // reset all validations
            this.programOptions.value = null; // to clear previously selected value
            this.teacherOptions.value = null; // to clear previously selected value
            this.teacherOptions.options = []; // to clear previously loaded options
            this.subjectOptions.value = null; // to clear previously selected value
            this.subjectOptions.options = []; // to clear previously loaded options
            this.discussion = "";
            this.discussionId = "";
            this.isEditing = false;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDiscussion: function (id) {
      const params = {
        discussion_id: id,
      };
      DiscussionService.getDiscussion(params)
        .then((result) => {
          if (result.data.status == "SUCCESS") {
            let discussionData = result.data.discussion;
            this.$refs.openModal.click();
            /* isEditing flag should be assigned as true only after modal click as it clears data when clicked on modal */
            this.isEditing = true;
            this.actionTitle = "Edit";
            this.programOptions.value = discussionData.program_id;
            this.fetchActiveSubscribedProgramTeachers();
            this.teacherId = discussionData.teacher_id;
            this.subjectId = discussionData.subject_id;
            this.discussion = discussionData.discussion;
            this.discussionId = discussionData.id;
          } else if (result.data.status == "ERROR") {
            this.$toast.error(result.data.message);
            this.$refs.closeModal.click();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    clearData: function () {
      this.actionTitle = "New";
      this.v$.$reset(); // reset all validations
      this.programOptions.value = null; // to clear previously selected value
      this.teacherOptions.value = null; // to clear previously selected value
      this.teacherOptions.options = []; // to clear previously loaded options
      this.subjectOptions.value = null; // to clear previously selected value
      this.subjectOptions.options = []; // to clear previously loaded options
      this.discussion = "";
      this.discussionId = "";
      this.isEditing = false;
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
